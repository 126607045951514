<template>
    <div>

        <v-app-bar color="#F0F0F0" height="100" flat style="border-bottom: solid 1px #C5C5C5;">

            <img src="@/assets/logo.png" width="60"/>
            <v-spacer></v-spacer>
            <div class="contact-us" @click="irPagina('https://wa.link/narkmu')">
                <i class="fas fa-headset mr-1"></i>
                Contact Us
            </div>


        </v-app-bar>

    </div>
</template>

<style>
.contact-us {
    border-radius: 10px;
    padding: 5px 10px;
    border: solid 1px #C5C5C5;
    cursor:pointer;
    color: #000;
    letter-spacing: 0;
    font-weight: 600;
}
.contact-us:hover {
    background: #c2c2c2;
}

</style>

<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        irPagina(e){
            window.open(e, '_blank');
        }
    }
}
</script>