<template>
  <v-app>
    <toolbar/>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
* {
  font-family: "Inter", sans-serif;
}
</style>

<script>
import toolbar from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
    toolbar
    }
};
</script>
